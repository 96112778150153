import { FilePlusIcon, UploadIcon } from '@radix-ui/react-icons';
import { BaseballIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { ManageListDialogHoC } from 'components/common/pitch-lists/manage-list';
import { SectionHeader } from 'components/sections/header';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { useContext, useState } from 'react';

export const PitchesHeader = (props: { extraActions?: IMenuAction[] }) => {
  const designCx = useContext(PitchDesignContext);
  const { getDefaultPitch } = useContext(MachineContext);

  const { restrictedGameStatus } = useContext(AuthContext);

  const { userSections, tryChangeSection } = useContext(SectionsContext);

  const [dialogCreateList, setDialogCreateList] = useState<number | undefined>(
    undefined
  );

  const restricted = restrictedGameStatus();

  return (
    <ErrorBoundary componentName="PitchesHeader">
      <SectionHeader
        header={t('main.pitches')}
        subsections={
          userSections?.find((s) => s.value === SectionName.Pitches)
            ?.subsections
        }
        actions={[
          {
            group: '_1',
            prefixIcon: <FilePlusIcon />,
            label: 'common.create-pitch-list',
            disabled: restricted,
            onClick: () => setDialogCreateList(Date.now()),
          },
          {
            group: '_1',
            prefixIcon: <BaseballIcon />,
            label: 'common.create-pitch',
            disabled: restricted,
            onClick: () => {
              // clears any existing reference pitch (e.g. from previously updating an existing pitch)
              designCx.setReference(getDefaultPitch());

              tryChangeSection({
                trigger: 'PitchesHeader > actions menu',
                section: SectionName.Pitches,
                subsection: SubSectionName.Design,
              });
            },
          },
          {
            group: '_1',
            prefixIcon: <UploadIcon />,
            label: 'common.upload-pitches',
            disabled: restricted,
            onClick: () =>
              tryChangeSection({
                trigger: 'PitchesHeader > actions menu',
                section: SectionName.Pitches,
                subsection: SubSectionName.Upload,
              }),
          },
          ...(props.extraActions ?? []),
        ]}
      />

      {dialogCreateList && (
        <ManageListDialogHoC
          key={dialogCreateList}
          identifier="PitchesHeaderCreateListDialog"
          mode="create"
          onCreated={() => setDialogCreateList(undefined)}
          onClose={() => setDialogCreateList(undefined)}
        />
      )}
    </ErrorBoundary>
  );
};
