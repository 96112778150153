import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { Box, Button, Flex, Spinner } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { SelectPitchBodyContent } from 'components/sections/quick-session/steps/select-pitch/body-content';
import { SelectPitchBodySidebar } from 'components/sections/quick-session/steps/select-pitch/body-sidebar';
import { SelectPitchHeaderContent } from 'components/sections/quick-session/steps/select-pitch/header-content';
import { SelectPitchHeaderSidebar } from 'components/sections/quick-session/steps/select-pitch/header-sidebar';
import { AimingContext, IAimingContext } from 'contexts/aiming.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { GlobalContext, IGlobalContext } from 'contexts/global.context';
import { HittersContext, IHittersContext } from 'contexts/hitters.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import {
  IMatchingShotsContext,
  MatchingShotsContext,
} from 'contexts/pitch-lists/matching-shots.context';
import {
  IQuickSessionContext,
  QuickSessionContext,
  QuickSessionStep,
} from 'contexts/quick-session.context';
import { IVideosContext, VideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { DEFAULT_PLATE, IPitch } from 'lib_ts/interfaces/pitches';
import React, { useContext } from 'react';

const COMPONENT_NAME = 'QuickSessionSelectPitch';

interface IProps {
  aimingCx: IAimingContext;
  authCx: IAuthContext;
  globalCx: IGlobalContext;
  hittersCx: IHittersContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  quickCx: IQuickSessionContext;
  videosCx: IVideosContext;
}

interface IState {}

export const SelectPitchHoC = () => {
  const props: IProps = {
    aimingCx: useContext(AimingContext),
    authCx: useContext(AuthContext),
    globalCx: useContext(GlobalContext),
    hittersCx: useContext(HittersContext),
    machineCx: useContext(MachineContext),
    matchingCx: useContext(MatchingShotsContext),
    quickCx: useContext(QuickSessionContext),
    videosCx: useContext(VideosContext),
  };

  return <SelectPitch {...props} />;
};

class SelectPitch extends React.Component<IProps, IState> {
  private resendTimeout?: NodeJS.Timeout;

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.selectPitch = this.selectPitch.bind(this);
    this.lazySendPitch = this.lazySendPitch.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.globalCx.dialogs.length === 0) {
      const ballBecameReady =
        prevProps.machineCx.lastBallCount !==
          this.props.machineCx.lastBallCount &&
        this.props.machineCx.lastBallCount === 1;

      if (ballBecameReady) {
        this.lazySendPitch(true);
      }
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.resendTimeout);
  }

  // will dequeue itself if called in rapid succession
  private lazySendPitch(auto: boolean) {
    if (this.props.globalCx.dialogs.length > 0) {
      console.warn(
        `${COMPONENT_NAME}: skipping sendPitch while a dialog was open`
      );
      clearTimeout(this.resendTimeout);
      return;
    }

    if (auto && !this.props.machineCx.checkActive(true)) {
      // silently skip auto-send if not connected
      return;
    }

    clearTimeout(this.resendTimeout);

    this.resendTimeout = setTimeout(() => {
      this.props.aimingCx.sendToMachine({
        training: false,
        skipPreview: false,
        trigger: `${COMPONENT_NAME} > sendPitch`,
        list: this.props.quickCx.active,
        onSuccess: (success) => {
          if (!success) {
            NotifyHelper.warning({
              message_md: 'Failed to send instructions to the machine.',
            });
          }
        },
      });
    }, 500);
  }

  private async selectPitch(pitch: IPitch) {
    if (this.props.matchingCx.isPitchTrained(pitch)) {
      this.props.aimingCx.setPlate(DEFAULT_PLATE);

      await this.props.aimingCx.setPitch(pitch);

      this.lazySendPitch(true);
      return;
    }

    // can't train yet
    if (!this.props.matchingCx.readyToTrain()) {
      return;
    }

    this.props.quickCx.trainPitches([pitch]);
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Box>
            <Button
              size={RADIX.BUTTON.SIZE.SM}
              variant="soft"
              color={RADIX.COLOR.NEUTRAL}
              onClick={() =>
                this.props.quickCx.setStep(QuickSessionStep.SelectCard)
              }
            >
              <ArrowLeftIcon /> {t('common.back')}
            </Button>
          </Box>

          {this.props.quickCx.loading ? (
            <Spinner size="3" />
          ) : (
            <Flex direction="column" gap={RADIX.FLEX.GAP.SIDEBAR}>
              <CommonContentWithSidebar
                left={<SelectPitchHeaderContent />}
                right={<SelectPitchHeaderSidebar />}
              />

              <CommonContentWithSidebar
                left={
                  <SelectPitchBodyContent lazySendPitch={this.lazySendPitch} />
                }
                right={
                  <SelectPitchBodySidebar lazySendPitch={this.lazySendPitch} />
                }
              />
            </Flex>
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
