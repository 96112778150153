import { BallType } from '../../../enums/machine.enums';
import { BuildPriority } from '../../../enums/pitches.enums';
import { ICustomSpins } from '../i-custom';

type YesNo = 'Y' | 'N';

export interface ITrajektData extends Partial<ICustomSpins> {
  MachineID: string | undefined;
  Nickname: string | undefined;

  Session: string | undefined;
  Date: string | undefined;
  Timestamp: string | undefined;

  // from msbs
  TimeOfPitchUTC: string | undefined;

  ShotNumber: number | undefined;

  BallType: BallType | undefined;
  BuildPriority: BuildPriority | undefined;

  InGame: YesNo | undefined;

  Training: YesNo | undefined;

  /** opposite of fire.data.rejected flag */
  Valid: YesNo | undefined;

  /** contains fire.data.rejected_msg */
  ErrorMsg: string | undefined;

  PitchList: string | undefined;

  TargetPlateX: number | undefined;

  TargetPlateZ: number | undefined;

  /** vertical break */
  TargetVB: number | undefined;

  /** horizontal break */
  TargetHB: number | undefined;

  /** vertical break */
  ActualVB: number | undefined;

  /** horizontal break */
  ActualHB: number | undefined;

  Hitter: string | undefined;
}

export interface ITrackingData {
  /** if rapsodo data exists, whether pitch strike pos side, strike pos height, and spin have full confidence */
  Validated: YesNo | undefined;
  PITCH_GyroDegree: number | undefined;
  PITCH_HBSpin: number | undefined;
  PITCH_HBTrajectory: number | undefined;
  PITCH_HorizontalAngle: number | undefined;
  PITCH_PlayerID: number | undefined;
  PITCH_ReleaseExtension: number | undefined;
  PITCH_ReleaseHeight: number | undefined;
  PITCH_ReleaseSide: number | undefined;
  PITCH_ReleaseTime: number | undefined;
  PITCH_Speed: number | undefined;
  PITCH_SpinAxis: number | undefined;
  PITCH_SpinEfficiency: number | undefined;
  PITCH_Strike: boolean | undefined;
  PITCH_StrikeZoneHeight: number | undefined;
  PITCH_StrikeZoneSide: number | undefined;
  PITCH_StrikeZoneTime: number | undefined;
  PITCH_TotalSpin: number | undefined;
  PITCH_TrueSpin: number | undefined;
  PITCH_VBSpin: number | undefined;
  PITCH_VBTrajectory: number | undefined;
  PITCH_VerticalAngle: number | undefined;
  PITCH_ZoneTime: number | undefined;
  HIT_Distance: number | undefined;
  HIT_LaunchAngle: number | undefined;
  HIT_ExitSpeed: number | undefined;
}

export const EMPTY_TRACKING_DATA: ITrackingData = {
  Validated: undefined,
  PITCH_GyroDegree: undefined,
  PITCH_HBSpin: undefined,
  PITCH_HBTrajectory: undefined,
  PITCH_HorizontalAngle: undefined,
  PITCH_PlayerID: undefined,
  PITCH_ReleaseExtension: undefined,
  PITCH_ReleaseHeight: undefined,
  PITCH_ReleaseSide: undefined,
  PITCH_ReleaseTime: undefined,
  PITCH_Speed: undefined,
  PITCH_SpinAxis: undefined,
  PITCH_SpinEfficiency: undefined,
  PITCH_Strike: undefined,
  PITCH_StrikeZoneHeight: undefined,
  PITCH_StrikeZoneSide: undefined,
  PITCH_StrikeZoneTime: undefined,
  PITCH_TotalSpin: undefined,
  PITCH_TrueSpin: undefined,
  PITCH_VBSpin: undefined,
  PITCH_VBTrajectory: undefined,
  PITCH_VerticalAngle: undefined,
  PITCH_ZoneTime: undefined,
  HIT_Distance: undefined,
  HIT_LaunchAngle: undefined,
  HIT_ExitSpeed: undefined,
};

/** determines the columns in the CSV export file */
export interface ICombinedData {
  Trajekt: Partial<ITrajektData>;
  Tracking: ITrackingData;
}
