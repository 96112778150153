import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ReorderDialog } from 'components/common/table/reorder-dialog';
import { CookiesContext } from 'contexts/cookies.context';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { IPageCoordinates } from 'interfaces/tables/pagination';
import { ITableReorder } from 'interfaces/tables/reordering';
import { ISortSpec } from 'interfaces/tables/sorting';
import { clamp } from 'lib_ts/classes/math.utilities';
import { IMongoBase, ISortable } from 'lib_ts/interfaces/mongo/_base';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const handleSort = (config: {
  a: any;
  b: any;
  column: ITableColumn;
  sort: ISortSpec;
}): number => {
  try {
    const { a, b, column } = config;
    const { key, dir } = config.sort;

    if (column.sortRowsFn) {
      /** special columns with unusual display values, like formatColumn/formatRow functions */
      return column.sortRowsFn(a, b, dir);
    }

    const dataType = typeof a[key];

    if (dataType === 'string') {
      const aStr = (a[key] ?? '').toLowerCase();
      const bStr = (b[key] ?? '').toLowerCase();

      return dir * -aStr.localeCompare(bStr, 'en', { ignorePunctuation: true });
    }

    /** best attempt */
    return dir * (a[key] > b[key] ? -1 : 1);
  } catch (e) {
    console.error(e);
    return 1;
  }
};

interface IProps {
  children: ReactNode;
}

export interface ITableContext {
  contextMenuTarget?: IMongoBase;
  setContextMenuTarget: (v: IMongoBase | undefined) => void;

  // updated whenever we need to force the table to re-render (e.g. data changed, data reordered)
  tableKey: number;
  readonly updateTableKey: () => void;

  readonly initReorder: (value: ITableReorder) => void;
  readonly showReorder: () => void;
  canReorder: boolean;

  // table should set this to ensure max page is correctly calculated
  readonly changeData: (data: IMongoBase[]) => void;
  // table should set this to ensure sorting works correctly
  readonly initColumns: (value: ITableColumn[]) => void;

  // CAUTION: model may be undefined
  readonly initAfterChangeSelected: (
    callback: (model: any | undefined) => void
  ) => void;

  // table can set this so that page size changes will be saved in the cookies, will also set page size from cookies if possible
  readonly initIdentifier: (value: TableIdentifier) => void;

  sort?: ISortSpec;

  /** undefined key => turn off sort
  undefined dir => next sort mode */
  readonly setSort: (value: Partial<ISortSpec>) => void;

  /** based on data length and page size */
  maxPage: number;

  /** initially undefined (i.e. infinite page size), should be updated if pagination is enabled */
  pageSize: number | undefined;

  /** lazy = true => if _pageSize is not undefined, skip the request */
  readonly setPageSize: (value: number, lazy: boolean) => void;

  /** if table is selectable, highlights selected row, reset on pagination, relative to current page */
  selected: IPageCoordinates;
  readonly setSelected: (value: Partial<IPageCoordinates>) => void;
  selectedData: IMongoBase | undefined;

  sortedData: IMongoBase[];

  pageData: IMongoBase[];

  readonly lastIndexOnPage: (page: number) => number;

  readonly lookupCoordinates: (key: string, value: any) => IPageCoordinates;
}

const DEFAULT: ITableContext = {
  setContextMenuTarget: () => console.debug('not init'),

  tableKey: Date.now(),
  updateTableKey: () => console.debug('not init'),

  initReorder: () => console.debug('not init'),
  showReorder: () => console.debug('not init'),
  canReorder: false,

  initColumns: () => console.debug('not init'),
  initIdentifier: () => console.debug('not init'),
  initAfterChangeSelected: () => console.debug('not init'),

  setSort: () => console.debug('not init'),
  changeData: () => console.debug('not init'),

  maxPage: 0,

  pageSize: undefined,
  setPageSize: () => console.debug('not init'),

  selected: {
    index: -1,
    page: 0,
  },
  setSelected: () => console.debug('not init'),
  selectedData: undefined,

  sortedData: [],

  pageData: [],

  lastIndexOnPage: () => -1,

  lookupCoordinates: () => ({
    page: 0,
    index: 0,
  }),
};

export const TableContext = createContext(DEFAULT);

/** for tracking misc details across entire app */
export const TableProvider: FC<IProps> = (props) => {
  const cookiesCx = useContext(CookiesContext);

  const [_identifier, _setIdentifier] = useState<TableIdentifier | undefined>();

  const [_data, _setData] = useState<IMongoBase[]>([]);

  const [_tableKey, _setTableKey] = useState(DEFAULT.tableKey);

  const [_sort, _setSort] = useState(DEFAULT.sort);

  useEffect(() => {
    _setTableKey(Date.now());
  }, [_data, _sort]);

  const [_selectedCallback, _setSelectedCallback] = useState<
    undefined | ((model: any | undefined) => void)
  >();

  const [_pageSize, _setPageSize] = useState(DEFAULT.pageSize);

  const _maxPage = useMemo(
    () =>
      _pageSize === undefined
        ? 0
        : Math.max(0, Math.ceil(_data.length / _pageSize) - 1),
    [_data, _pageSize]
  );

  const [_selected, _setSelected] = useState(DEFAULT.selected);

  const [_columns, _setColumns] = useState<ITableColumn[]>([]);

  const [_reorderProps, _setReorderProps] = useState<
    ITableReorder | undefined
  >();

  const [_dialogReorder, _setDialogReorder] = useState<number | undefined>();

  const _sorted = useMemo(() => {
    const sorted = [..._data];
    const sortColumn = _columns.find((c) => c.key === _sort?.key);

    if (sortColumn && _sort && _sort.dir) {
      sorted.sort((a, b) =>
        handleSort({
          a: a,
          b: b,
          column: sortColumn,
          sort: _sort,
        })
      );
    } else {
      /** sort by _sort column by default */
      sorted.sort((a: ISortable, b: ISortable) => {
        return (a._sort ?? -1) < (b._sort ?? -1) ? -1 : 1;
      });
    }

    return sorted;
  }, [_data, _columns, _sort, _tableKey]);

  const _pageData = useMemo(() => {
    if (_pageSize === undefined) {
      return _sorted;
    }

    const iStart = _selected.page * _pageSize;
    const iEnd = (_selected.page + 1) * _pageSize;

    return _sorted.slice(iStart, iEnd);
  }, [_sorted, _selected.page, _pageSize]);

  const _selectedData = useMemo<IMongoBase | undefined>(
    () => _pageData[_selected.index],
    [_selected.index, _pageData]
  );

  // trigger when selection changes
  useEffect(() => {
    if (!_selectedCallback) {
      return;
    }

    if (!_selected.key) {
      return;
    }

    if (!_selectedData) {
      return;
    }

    _selectedCallback(_selectedData);
  }, [_selected.key, _selectedData, _selectedCallback]);

  const _lastIndexOnPage = useCallback(
    (page: number) => {
      if (_pageSize === undefined) {
        return _sorted.length - 1;
      }

      const start = page * _pageSize;
      const pageData = _sorted.slice(start, start + _pageSize);

      const output = pageData.length - 1;
      return output;
    },
    [_pageSize, _sorted]
  );

  const _indexToCoordinates = useCallback(
    (i: number): IPageCoordinates => {
      if (_pageSize === undefined) {
        // one giant page
        return {
          page: 0,
          index: i,
        };
      }

      if (i === -1) {
        // failed to find, go to first page w/o selection
        return {
          page: 0,
          index: -1,
        };
      }

      const page = Math.floor(i / _pageSize);
      return {
        page: page,
        index: i - page * _pageSize,
      };
    },
    [_pageSize]
  );

  const _lookupCoordinates = useCallback(
    (key: string, value: any): IPageCoordinates => {
      const i = _sorted.findIndex((m: any) => m[key] === value);
      return _indexToCoordinates(i);
    },
    [_sorted, _indexToCoordinates]
  );

  const _safeSetSelected = useCallback(
    (value: Partial<IPageCoordinates>) => {
      console.debug('trying to safely set selected', value);

      const safePage = clamp(value.page ?? _selected.page, 0, _maxPage);

      if (isNaN(safePage)) {
        console.warn('cannot change to NaN page');
        return;
      }

      // -1 to allow for selecting nothing on a page
      const safeIndex = clamp(
        value.index ?? -1,
        -1,
        _lastIndexOnPage(safePage)
      );

      if (isNaN(safeIndex)) {
        console.warn('cannot change to NaN index');
        return;
      }

      NotifyHelper.debug({
        message_md: `TableContext changing selected:\n\n* from page ${_selected.page}, item ${_selected.index}\n* to page ${safePage}, item ${safeIndex}`,
      });

      _setSelected({
        key: value.key,
        page: safePage,
        index: safeIndex,
      });
    },
    [_selected, _maxPage, _lastIndexOnPage]
  );

  // record page size for identified tables in cookies
  useEffect(() => {
    if (_identifier === undefined) {
      return;
    }

    if (_pageSize === undefined) {
      return;
    }

    cookiesCx.setPageSize(_identifier, _pageSize);
  }, [_identifier, _pageSize]);

  const [_contextMenuTarget, _setContextMenuTarget] = useState(
    DEFAULT.contextMenuTarget
  );

  const state: ITableContext = {
    contextMenuTarget: _contextMenuTarget,
    setContextMenuTarget: _setContextMenuTarget,

    tableKey: _tableKey,
    updateTableKey: () => {
      // this will force the table to redraw with the newest order
      _setTableKey(Date.now());
    },

    initReorder: _setReorderProps,
    showReorder: () => _setDialogReorder(Date.now()),
    canReorder: _reorderProps !== undefined,

    initIdentifier: (value) => {
      _setIdentifier(value);

      const defaultPageSize = cookiesCx.getPageSize(value);
      if (defaultPageSize !== undefined) {
        _setPageSize(defaultPageSize);
      }
    },
    changeData: (data) => {
      // keep this logger in case we start triggering this way too much by accident (e.g. table data is constantly resetting)
      console.debug('changing table data', data.length);

      // this avoids unnecessarily triggering afterChangeSelected actions when data changes
      _safeSetSelected({ index: -1 });

      _setData(data);
    },
    initColumns: _setColumns,
    initAfterChangeSelected: (callback) => _setSelectedCallback(() => callback),

    sort: _sort,
    setSort: (config) => {
      const { key, dir } = config;

      _setSelected({
        index: -1,
        page: _selected.page,
      });

      if (key === undefined) {
        // remove sort, ignore any dir value
        _setSort(undefined);
        return;
      }

      if (dir !== undefined) {
        // go directly to requested sorting method, probably not triggered
        _setSort({
          key: key,
          dir: dir,
        });
        return;
      }

      if (key === _sort?.key) {
        // same key, next direction
        switch (_sort?.dir) {
          case 1: {
            // next sort
            _setSort({
              key: key,
              dir: -1,
            });
            return;
          }

          case -1: {
            // remove sort
            _setSort(undefined);
            return;
          }

          case undefined:
          default: {
            // default sort
            _setSort({
              key: key,
              dir: 1,
            });
            return;
          }
        }
      }

      // different key, default direction
      _setSort({
        key: key,
        dir: 1,
      });
    },

    sortedData: _sorted,

    maxPage: _maxPage,

    pageSize: _pageSize,
    setPageSize: (value, lazy) => {
      if (_pageSize !== undefined && lazy) {
        return;
      }

      _setPageSize(value);
    },

    selected: _selected,
    setSelected: _safeSetSelected,
    selectedData: _selectedData,

    pageData: _pageData,

    lastIndexOnPage: _lastIndexOnPage,

    lookupCoordinates: _lookupCoordinates,
  };

  return (
    <TableContext.Provider value={state}>
      {props.children}

      {_dialogReorder && _reorderProps && (
        <ReorderDialog key={_dialogReorder} {..._reorderProps} />
      )}
    </TableContext.Provider>
  );
};
