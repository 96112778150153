import { NotifyHelper } from 'classes/helpers/notify.helper';
import { EditHitterDialogHoC } from 'components/common/dialogs/edit-hitter';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { DeleteHittersDialog } from 'components/sections/hitter-library/dialogs/delete-hitters';
import { AuthContext } from 'contexts/auth.context';
import { lightFormat, parseISO } from 'date-fns';
import { CrudAction } from 'enums/tables';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { HITTER_SIDES, HitterSide } from 'lib_ts/enums/hitters.enums';
import { PLAYER_LEVELS, PlayerLevel } from 'lib_ts/enums/pitches.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IHitter, IHitterStats } from 'lib_ts/interfaces/i-hitter';
import { IHitterExt } from 'lib_ts/interfaces/i-session-event';
import {
  DEFAULT_STRIKEZONE,
  STRINGER_STRIKEZONES,
} from 'lib_ts/interfaces/i-strike-zone';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HittersService } from 'services/hitters.service';

const CREATE_HITTER_ID = '----create----';

/** in inches */
const STRINGER_STRIKEZONES_HEIGHTS = STRINGER_STRIKEZONES.map(
  (s) => s.height_in
).sort();
const MIN_HITTER_HEIGHT_IN = STRINGER_STRIKEZONES_HEIGHTS[0];
const MAX_HITTER_HEIGHT_IN =
  STRINGER_STRIKEZONES_HEIGHTS[STRINGER_STRIKEZONES_HEIGHTS.length - 1];

/**
 *
 * @param height_ft will be used to determine strike zone that's the closest match
 * @returns
 */
const getZone = (height_ft?: number) => {
  if (height_ft !== undefined && !isNaN(height_ft)) {
    /** if there is a failure to find, the closest zone will be used */
    const height_in = Math.round(height_ft * 12);
    if (height_in <= MIN_HITTER_HEIGHT_IN) {
      /** use the min strike zone */
      return STRINGER_STRIKEZONES.find(
        (s) => s.height_in === MIN_HITTER_HEIGHT_IN
      );
    } else if (height_in >= MAX_HITTER_HEIGHT_IN) {
      /** use the max strike zone */
      return STRINGER_STRIKEZONES.find(
        (s) => s.height_in === MAX_HITTER_HEIGHT_IN
      );
    } else {
      /** find the strike zone by height */
      return STRINGER_STRIKEZONES.find((s) => s.height_in === height_in);
    }
  }
};

/** values used for spawning and resetting stats entries */
export const getEmptyStats = (hitter_id: string): IHitterStats => ({
  hitter_id: hitter_id,
  pitches: 0,
  swings: 0,
  hits: 0,
});

interface ICrudConfig {
  key: number;
  action: CrudAction;
  models: IHitter[];
  onClose?: () => void;
}

interface IOptionsDict {
  _created: string[];
}

interface IFilterState extends Partial<IHitter> {}

export interface IHittersContext {
  filters: IFilterState;
  readonly setFilters: (filters: IFilterState) => void;

  filtered: IHitter[];

  active?: IHitter;

  hitters: IHitter[];

  stats: IHitterStats[];

  /** unique values for each key */
  options: IOptionsDict;

  loading: boolean;

  readonly getHitterExt: (hitter_id: string) => IHitterExt | undefined;

  /** change the active_id */
  readonly setActive: (hitter_id?: string) => void;

  /** inserts (it doesn't exist) or updates (if it exists) the stats entry for a given hitter, provide only a partial to only modify specific attributes */
  readonly upsertStats: (
    hitter_id: string,
    value: Partial<IHitterStats>
  ) => Promise<IHitterStats[]>;
  /** reverts the stats entry for a given hitter to the default (starting) values */
  readonly resetStats: (hitter_id: string) => void;
  readonly create: (
    payload: Partial<IHitter>,
    onCreate?: (value: IHitter) => void
  ) => Promise<boolean>;
  readonly update: (
    payload: Partial<IHitter>,
    onUpdate?: (value: IHitter) => void
  ) => Promise<boolean>;
  readonly delete: (ids: string[]) => Promise<boolean>;

  readonly getInput: (
    mode: 'side' | 'level' | 'hitter',
    // e.g. reset table after changing filters
    callback?: () => void
  ) => JSX.Element;

  readonly openCrudDialog: (config: ICrudConfig) => void;
}

const DEFAULT: IHittersContext = {
  filters: {},
  setFilters: () => console.debug('not init'),
  filtered: [],

  hitters: [],

  stats: [],

  options: {
    _created: [],
  },

  loading: false,

  getHitterExt: () => undefined,
  setActive: () => console.debug('not init'),
  upsertStats: async () => new Promise(() => console.debug('not init')),
  resetStats: () => console.debug('not init'),
  create: async () => new Promise(() => console.debug('not init')),
  delete: async () => new Promise(() => console.debug('not init')),
  update: async () => new Promise(() => console.debug('not init')),

  getInput: () => <></>,
  openCrudDialog: () => console.debug('not init'),
};

export const HittersContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

const getOptions = (v: IHitter[]): IOptionsDict => {
  if (v) {
    return {
      _created: ArrayHelper.unique(
        v.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const HittersProvider: FC<IProps> = (props) => {
  const [_active, _setActive] = useState(DEFAULT.active);

  const [_hitters, _setHitters] = useState(DEFAULT.hitters);
  const _options = useMemo(() => getOptions(_hitters), [_hitters]);

  const [_filters, _setFilters] = useState(DEFAULT.filters);
  const [_stats, _setStats] = useState(DEFAULT.stats);
  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const { current } = useContext(AuthContext);

  const [_crudConfig, _setCrudConfig] = useState<ICrudConfig | undefined>();

  /** reload data to match session access */
  const _fetchKey = useMemo(() => {
    /** trigger refresh only once logged in/successfully resumed */
    if (!current.auth) {
      return undefined;
    }

    if (!current.session) {
      return undefined;
    }

    return Date.now();
  }, [current.auth, current.session]);

  const [_searchKey, _setSearchKey] = useState(Date.now());

  const _filtered = useMemo(() => {
    return _hitters
      .filter((h) => !_filters.side || _filters.side === h.side)
      .filter((h) => !_filters.level || _filters.level === h.level);
  }, [_hitters, _filters]);

  const state: IHittersContext = {
    filters: _filters,
    setFilters: _setFilters,
    filtered: _filtered,

    hitters: _hitters,
    stats: _stats,
    options: _options,
    loading: _loading,

    active: _active,

    getHitterExt: (hitter_id) => {
      const hitter = _hitters.find((h) => h._id === hitter_id);
      if (!hitter) {
        return;
      }

      const hitterStats = _stats.find((s) => s.hitter_id === hitter_id);
      if (!hitterStats) {
        return;
      }

      const output: IHitterExt = {
        ...hitter,
        stats: hitterStats,
        zone: getZone(hitter.height_ft) ?? DEFAULT_STRIKEZONE,
      };

      return output;
    },

    setActive: (hitter_id) => {
      _setActive(_hitters.find((h) => h._id === hitter_id));
    },

    upsertStats: async (hitter_id, value) =>
      new Promise((resolve) => {
        // because the set function is async
        const index = _stats.findIndex((s) => s.hitter_id === hitter_id);
        const current = index === -1 ? getEmptyStats(hitter_id) : _stats[index];
        const next: IHitterStats = {
          ...current,
          ...value,
        };

        if (index === -1) {
          _stats.push(next);
        } else {
          _stats[index] = next;
        }

        _setStats(_stats);
        resolve(_stats);
      }),

    resetStats: (hitter_id) => {
      const index = _stats.findIndex((s) => s.hitter_id === hitter_id);
      if (index !== -1) {
        _stats[index] = getEmptyStats(hitter_id);
      }
    },

    delete: async (ids) => {
      try {
        _setLoading(true);

        const success = await HittersService.getInstance().deleteHitters(ids);

        if (!success) {
          throw new Error('failed to delete hitters');
        }

        NotifyHelper.success({
          message_md:
            ids.length === 1
              ? 'Hitter deleted.'
              : `${ids.length} hitters deleted.`,
        });

        setTimeout(() => {
          /** remove from context */
          const newValues = _hitters.filter((v) => !ids.includes(v._id));
          _setHitters(newValues);
        }, 500);

        return success;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    create: async (payload, onCreate) => {
      try {
        _setLoading(true);

        const result = await HittersService.getInstance().postHitter(payload);
        const newHitters = [..._hitters];

        const index = newHitters.findIndex((v) => v._id === result._id);
        if (index !== -1) {
          /** replace current context value with updated result */
          newHitters.splice(index, 1, result);
        } else {
          /** append to end */
          newHitters.push(result);
        }

        _setHitters(newHitters);

        NotifyHelper.success({ message_md: 'Hitter created!' });

        if (onCreate) {
          onCreate(result);
        }

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    update: async (payload, onUpdate) => {
      try {
        _setLoading(true);

        const result = await HittersService.getInstance().updateHitter(payload);

        const newHitters = [..._hitters];

        const index = newHitters.findIndex((v) => v._id === result._id);
        if (index !== -1) {
          /** replace current context value with updated result */
          newHitters.splice(index, 1, result);
        } else {
          /** append to end */
          newHitters.push(result);
        }

        _setHitters(newHitters);

        if (_active?._id === result._id) {
          _setActive(result);
        }

        NotifyHelper.success({ message_md: 'Hitter updated!' });

        if (onUpdate) {
          onUpdate(result);
        }

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    getInput: (mode, callback) => {
      switch (mode) {
        case 'side': {
          return (
            <CommonSelectInput
              id="hitters-side"
              name="side"
              placeholder="hitters.hitter-side"
              options={HITTER_SIDES}
              value={_filters.side}
              onChange={(v) => {
                const current = _filters;

                if (!v) {
                  delete current.side;
                } else {
                  current.side = v as HitterSide;
                }

                _setFilters({ ...current });

                callback?.();
              }}
              disabled={_loading}
              optional
            />
          );
        }

        case 'level': {
          return (
            <CommonSelectInput
              id="hitters-level"
              name="level"
              placeholder="common.level"
              options={PLAYER_LEVELS}
              value={_filters.level}
              onChange={(v) => {
                const current = _filters;

                if (!v) {
                  delete current.level;
                } else {
                  current.level = v as PlayerLevel;
                }

                _setFilters({ ...current });
              }}
              disabled={_loading}
              optional
              skipSort
            />
          );
        }

        case 'hitter':
        default: {
          return (
            <CommonSearchInput
              key={_searchKey}
              id="hitters-hitter"
              placeholder="common.hitter"
              options={[
                {
                  label: t('hitters.add-a-new-hitter'),
                  value: CREATE_HITTER_ID,
                },
                ..._filtered.map((h) => {
                  const o: IOption = {
                    label: h.name,
                    value: h._id,
                    group: `${h.level}${h.side ? `: ${h.side}` : ''}`,
                  };

                  return o;
                }),
              ]}
              values={_active ? [_active._id] : []}
              onChange={(v) => {
                if (v.length === 0) {
                  _setActive(undefined);
                  return;
                }

                const hitterID = v[0];

                if (!hitterID) {
                  return;
                }

                if (hitterID === CREATE_HITTER_ID) {
                  _setCrudConfig({
                    key: Date.now(),
                    action: CrudAction.Create,
                    models: [],
                  });
                  return;
                }

                _setActive(_filtered.find((h) => h._id === hitterID));
              }}
              optional
            />
          );
        }
      }
    },

    openCrudDialog: (config) => {
      _setCrudConfig(config);
    },
  };

  /** fetch the data at load */
  useEffect(() => {
    if (_fetchKey === undefined) {
      return;
    }

    _setLoading(true);

    HittersService.getInstance()
      .getHitters()
      .then((v) => _setHitters(v))
      .finally(() => _setLoading(false));
  }, [_fetchKey]); // dependency list => run whenever lastFetched is changed

  useEffect(() => {
    // active selection may not be visible after filtering
    _setActive(undefined);
  }, [_filters]);

  return (
    <HittersContext.Provider value={state}>
      {props.children}

      {_crudConfig?.action === CrudAction.Create && (
        <EditHitterDialogHoC
          key={_crudConfig.key}
          hitter_id={undefined}
          onCreate={(value) => _setActive(value)}
          onClose={() => {
            _crudConfig?.onClose?.();
            _setCrudConfig(undefined);
            _setSearchKey(Date.now());
          }}
        />
      )}

      {_crudConfig?.action === CrudAction.Update &&
        _crudConfig.models.length > 0 && (
          <EditHitterDialogHoC
            key={_crudConfig.key}
            hitter_id={_crudConfig.models[0]._id}
            onClose={() => {
              _crudConfig.onClose?.();
              _setCrudConfig(undefined);
              _setSearchKey(Date.now());
            }}
          />
        )}

      {_crudConfig?.action === CrudAction.Delete && (
        <DeleteHittersDialog
          key={_crudConfig.key}
          hitters={_crudConfig.models}
        />
      )}
    </HittersContext.Provider>
  );
};
