import { CheckCircledIcon, DownloadIcon } from '@radix-ui/react-icons';
import { Code } from '@radix-ui/themes';
import { AimingContextHelper } from 'classes/helpers/aiming-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTable } from 'components/common/table';
import { TableProvider } from 'components/common/table/context';
import { ActiveCalibrationModelWarning } from 'components/common/warnings/active-calibration-model-warning';
import { PresetTrainingDialog } from 'components/machine/dialogs/preset-training';
import { TrainingDialog } from 'components/machine/dialogs/training';
import { PitchListsToolbar } from 'components/sections/pitch-lists/toolbar';
import { PitchesHeader } from 'components/sections/pitches/header';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext, IGlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
  ICheckedContext,
} from 'contexts/layout/checked.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import {
  IPitchListsContext,
  PitchListsContext,
} from 'contexts/pitch-lists/lists.context';
import {
  IMatchingShotsContext,
  MatchingShotsContext,
} from 'contexts/pitch-lists/matching-shots.context';
import { ISectionsContext, SectionsContext } from 'contexts/sections.context';
import { TrainingContext, TrainingProvider } from 'contexts/training.context';
import { IVideosContext, VideosContext } from 'contexts/videos/videos.context';
import lightFormat from 'date-fns/lightFormat';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { TrainingStatus } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { DEFAULT_PLATE, IPitch, IPitchList } from 'lib_ts/interfaces/pitches';
import React, { useContext } from 'react';
import { MainService } from 'services/main.service';
import { PitchesService } from 'services/pitches.service';
import slugify from 'slugify';

const COMPONENT_NAME = 'PitchLists';

const ENABLE_TRAIN_LISTS = false;

const IDENTIFIER = TableIdentifier.PitchLists;

// notify user every x pitch lists as they are being exported
const PROGRESS_FREQUENCY = 50;

interface IBaseProps {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  globalCx: IGlobalContext;
  listsCx: IPitchListsContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  sectionsCx: ISectionsContext;
  videosCx: IVideosContext;
}

interface IProps extends IBaseProps {
  checkedCx: ICheckedContext;
}

interface IState {
  dialogTraining?: number;

  trainPitches?: IPitch[];
}

const DEFAULT_STATE: IState = {};

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

export const PitchListsHoC = () => {
  const listsCx = useContext(PitchListsContext);

  const props: IBaseProps = {
    listsCx: listsCx,
    authCx: useContext(AuthContext),
    cookiesCx: useContext(CookiesContext),
    globalCx: useContext(GlobalContext),
    machineCx: useContext(MachineContext),
    matchingCx: useContext(MatchingShotsContext),
    sectionsCx: useContext(SectionsContext),
    videosCx: useContext(VideosContext),
  };

  return (
    <TableProvider>
      <CheckedProvider data={listsCx.filteredLists}>
        <CheckedContext.Consumer>
          {(checkedCx) => <PitchLists {...props} checkedCx={checkedCx} />}
        </CheckedContext.Consumer>
      </CheckedProvider>
    </TableProvider>
  );
};

class PitchLists extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.getExportLists = this.getExportLists.bind(this);
    this.exportListsContentsCSV = this.exportListsContentsCSV.bind(this);
    this.getCheckedMenuActions = this.getCheckedMenuActions.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderTrainingDialog = this.renderTrainingDialog.bind(this);
    this.updateCheckedLists = this.updateCheckedLists.bind(this);
  }

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.created',
      key: '_created',
      dataType: 'date',
    },
    {
      label: 'common.name',
      key: 'name',
    },
    {
      label: 'common.visibility',
      key: '_parent_def',
      formatFn: (list: IPitchList) => {
        switch (list._parent_def) {
          case 'teams':
            return t('common.team');

          case 'team-machines':
            return t('common.machine');

          case 'team-users':
            return t('common.personal');

          default:
            return t('common.unknown');
        }
      },
    },
    {
      label: 'pl.folder',
      key: 'folder',
      formatFn: (list: IPitchList) =>
        list.folder ? <Code>{list.folder}</Code> : <></>,
    },
    {
      label: 'common.type',
      labelIcon: <SuperAdminIcon />,
      key: 'type',
      invisible: this.props.authCx.current.role !== UserRole.admin,
    },
  ];

  /** exports lists as CSV file */
  private exportListsCSV(mode: 'checked' | 'all', lists: IPitchList[]) {
    if (lists.length === 0) {
      NotifyHelper.warning({ message_md: 'Empty export request.' });
      return;
    }

    MainService.getInstance()
      .convertJSONToCSV(lists)
      .then((csvString) => {
        const blob = new Blob([csvString], { type: 'text/csv' });
        MiscHelper.saveAs(
          blob,
          `pitch-lists_${mode}_${lightFormat(new Date(), 'yyyy-MM-dd')}.csv`
        );
      });
  }

  /** exports lists' contents as CSV file */
  private async exportListsContentsCSV(lists: IPitchList[]) {
    if (lists.length === 0) {
      NotifyHelper.warning({ message_md: 'Empty export request.' });
      return;
    }

    // get each list's content, save each as a file
    const plService = PitchesService.getInstance();
    const mainService = MainService.getInstance();

    for (let i = 0; i < lists.length; i++) {
      await MiscHelper.sleep(500);

      if (i > 0 && (i + 1) % PROGRESS_FREQUENCY === 0) {
        NotifyHelper.info({
          message_md: `Progress: ${i + 1} / ${lists.length} lists exported...`,
        });
      }

      const list = lists[i];

      const pitches = await plService.searchPitches(
        {
          _parent_def: 'pitch-lists',
          _parent_field: 'pitches',
          _parent_id: list._id,
          // ignored for search pitches
          limit: 0,
        },
        0
      );

      if (pitches.length === 0) {
        NotifyHelper.warning({
          message_md: `No pitches found for "${list.name}".`,
        });
        continue;
      }

      const customPitches = pitches.map((p) => {
        return PitchListHelper.convertToCustomExport({
          pitch: p,
          plate_ft: this.props.machineCx.machine.plate_distance,
          video: this.props.videosCx.getVideo(p.video_id),
        });
      });

      const csvString = await mainService.convertJSONToCSV(customPitches);
      const blob = new Blob([csvString], { type: 'text/csv' });
      MiscHelper.saveAs(blob, `${slugify(list.name)}.csv`);
    }

    NotifyHelper.success({
      message_md: `Progress: ${lists.length} lists' contents exported.`,
      delay_ms: 0,
    });
  }

  private getExportLists(mode: string): IPitchList[] {
    const output = this.props.listsCx.filteredLists;

    if (mode === 'all') {
      if (output.length === this.props.listsCx.lists.length) {
        /** no filters applied, export everything using an empty ids list */
        return this.props.listsCx.lists;
      }

      /** export all filtered videos */
      return output;
    }

    if (mode === 'checked') {
      return output.filter((v) => this.props.checkedCx.getChecked(v._id));
    }

    return [];
  }

  private async handleTrain() {
    const lists = this.props.listsCx.filteredLists.filter((v) =>
      this.props.checkedCx.getChecked(v._id)
    );

    if (lists.length === 0) {
      NotifyHelper.warning({
        message_md: 'Please select at least one list to train and try again.',
      });
      return;
    }

    NotifyHelper.warning({
      message_md: `Loading data to train ${lists.length} lists, please wait as this may take awhile...`,
    });

    const allPitches = (
      await Promise.all(
        lists.map((l) => PitchesService.getInstance().getListPitches(l._id))
      )
    ).flat();

    await this.props.matchingCx.updatePitches({
      pitches: allPitches,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });

    const untrainedPitches = allPitches.filter(
      (p) => !this.props.matchingCx.isPitchTrained(p)
    );

    if (untrainedPitches.length === 0) {
      NotifyHelper.success({
        message_md: 'There are no untrained pitches in the selected lists!',
      });
      return;
    }

    const aimedPitches = untrainedPitches
      .map((pitch) =>
        AimingContextHelper.getAdHocAimed({
          source: `${COMPONENT_NAME} > trainChecked`,
          machine: this.props.machineCx.machine,
          pitch: pitch,
          plate: DEFAULT_PLATE,
          usingShots: [],
        })
      )
      .map((m) => m?.pitch as IPitch)
      .filter((m) => !!m);

    if (aimedPitches.length < untrainedPitches.length) {
      NotifyHelper.warning({
        message_md: `No models for ${this.props.machineCx.machine.machineID} found for one or more pitches. Please refresh models and trying again.`,
      });
      return;
    }

    this.setState({
      dialogTraining: Date.now(),
      trainPitches: aimedPitches,
    });
  }

  private getCheckedMenuActions(checked: IPitchList[]): IMenuAction[] {
    const output: IMenuAction[] = [
      {
        prefixIcon: <DownloadIcon />,
        label: 'common.export-checked-pitches',
        onClick: () => this.exportListsContentsCSV(checked),
      },
      {
        prefixIcon: <DownloadIcon />,
        label: 'common.export-checked-lists',
        onClick: () => this.exportListsCSV('checked', checked),
      },
      {
        prefixIcon: <CheckCircledIcon />,
        invisible: !ENABLE_TRAIN_LISTS,
        label: 'common.train-checked',
        onClick: () => this.handleTrain(),
        color: RADIX.COLOR.TRAIN_PITCH,
        disabled: !this.props.matchingCx.readyToTrain(),
      },
    ];

    return output;
  }

  private renderTable() {
    const data = this.props.listsCx.filteredLists;

    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      total: data.length,
      enablePagination: true,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
    };

    const select: ITableSelectable = {
      enableSelect: true,
      afterChangeSelected: (m: IPitchList | undefined) => {
        if (!m) {
          return;
        }

        this.props.sectionsCx.tryChangeSection({
          trigger: 'pitch lists',
          section: SectionName.Pitches,
          subsection: SubSectionName.List,
          fragments: [m._id],
        });
      },
    };

    const checkable: ITableCheckable = {
      checkboxColumnIndex: 0,
      // todo: kind of weird deviation from rules here, since the checkboxes interact with the actions menu at the top rather than a checked menu at the bottom
      checkedActions: this.getCheckedMenuActions,
    };

    return (
      <CommonTable
        id="PitchLists"
        displayColumns={this.BASE_COLUMNS}
        displayData={data}
        toolbarContent={<PitchListsToolbar />}
        enableListener={this.props.globalCx.dialogs.length === 0}
        {...pagination}
        {...checkable}
        {...select}
        {...sort}
        vFlex
      />
    );
  }

  /** update any lists whose training status may have changed because of training */
  private async updateCheckedLists() {
    const machineID = this.props.machineCx.machine.machineID;
    const lists = this.props.listsCx.filteredLists.filter((v) =>
      this.props.checkedCx.getChecked(v._id)
    );

    for (let i = 0; i < lists.length; i++) {
      const list = lists[i];

      const pitches = await PitchesService.getInstance().getListPitches(
        list._id
      );

      await this.props.matchingCx.updatePitches({
        pitches: pitches,
        includeHitterPresent: false,
        includeLowConfidence: true,
      });

      const nextTraining = PitchListHelper.getTrainingDict({
        machineID: machineID,
        current: list.training,
        total: pitches.length,
        untrained: pitches.filter(
          (p) => !this.props.matchingCx.isPitchTrained(p)
        ).length,
      });

      const oldStatus = list.training?.[machineID] ?? TrainingStatus.Unknown;

      if (nextTraining[machineID] !== oldStatus) {
        await this.props.listsCx.updateList({
          payload: { _id: list._id, training: nextTraining },
          silently: true,
        });
      }
    }
  }

  private renderTrainingDialog() {
    if (!this.state.dialogTraining) {
      return;
    }

    if (!this.state.trainPitches) {
      return;
    }

    const mode = this.props.authCx.effectiveTrainingMode();

    if (mode === TrainingMode.Manual) {
      return (
        <TrainingProvider mode={mode}>
          <TrainingContext.Consumer>
            {(trainingCx) => (
              <TrainingDialog
                key={this.state.dialogTraining}
                identifier="PLS-TrainingDialog"
                machineCx={this.props.machineCx}
                trainingCx={trainingCx}
                pitches={this.state.trainPitches ?? []}
                threshold={this.props.machineCx.machine.training_threshold}
                onClose={() => {
                  this.setState(
                    {
                      dialogTraining: undefined,
                    },
                    () => {
                      this.updateCheckedLists();
                    }
                  );
                }}
              />
            )}
          </TrainingContext.Consumer>
        </TrainingProvider>
      );
    }

    return (
      <TrainingProvider mode={mode}>
        <TrainingContext.Consumer>
          {(trainingCx) => (
            <PresetTrainingDialog
              key={this.state.dialogTraining}
              identifier="PLS-PT-TrainingDialog"
              machineCx={this.props.machineCx}
              trainingCx={trainingCx}
              pitches={this.state.trainPitches ?? []}
              onClose={() => {
                this.setState(
                  {
                    dialogTraining: undefined,
                  },
                  () => {
                    this.updateCheckedLists();
                  }
                );
              }}
            />
          )}
        </TrainingContext.Consumer>
      </TrainingProvider>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={
            <>
              <PitchesHeader
                extraActions={[
                  {
                    prefixIcon: <DownloadIcon />,
                    label: 'common.export-all-pitches',
                    onClick: () =>
                      this.exportListsContentsCSV(
                        this.props.listsCx.filteredLists
                      ),
                  },
                  {
                    prefixIcon: <DownloadIcon />,
                    label: 'common.export-all-lists',
                    onClick: () =>
                      this.exportListsCSV(
                        'all',
                        this.props.listsCx.filteredLists
                      ),
                  },
                ]}
              />
              <ActiveCalibrationModelWarning showSettingsButton />
            </>
          }
          table={this.renderTable()}
        />

        {this.renderTrainingDialog()}
      </ErrorBoundary>
    );
  }
}
