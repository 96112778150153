import env from 'config';
import { addMilliseconds, isFuture } from 'date-fns';
import { t } from 'i18next';
import { INotification } from 'interfaces/i-notification';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { ErrorLevel } from 'lib_ts/enums/errors.enums';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { IAnnouncement } from 'lib_ts/interfaces/common/i-announcement';
import { IErrorType } from 'lib_ts/interfaces/common/i-error-type';
import { AppNotificationLogsService } from 'services/app-notification-logs.service';
import i18n from 'translations/i18n';
import { v4 } from 'uuid';

export const DEFAULT_DELAY_MS = 5_000;

const TOAST_KEY = '_toast';
const FUN_KEY = '_fun';

const lastSent: { [key: string]: Date | undefined } = {};

const COOLDOWN_DELAY_MS = 5_000;

const dispatch = (data: INotification) => {
  try {
    const hash = MiscHelper.hashify(data.message_md);
    if (!hash) {
      return;
    }

    // if the same message is being spammed, suppress all subsequent instances within cooldown
    const lastDate = lastSent[hash];
    if (lastDate && isFuture(addMilliseconds(lastDate, COOLDOWN_DELAY_MS))) {
      return;
    }

    lastSent[hash] = new Date();

    // triggers toast for user
    document.dispatchEvent(
      new CustomEvent(TOAST_KEY, {
        detail: {
          ...data,
          id: data.id ?? v4(),
          created: new Date(),
        },
      })
    );

    // records toast info on database
    if (
      data.level &&
      [ErrorLevel.error, ErrorLevel.warning].includes(data.level)
    )
      AppNotificationLogsService.getInstance().insert({
        env: env.identifier,
        content_md: data.message_md,
        level: data.level,
        hash: hash,
      });
  } catch (e) {
    console.error(e);
  }
};

export class NotifyHelper {
  static onToast(callback: (message: any) => void) {
    /** callback must be **exactly** what will be provided to removeEventListener */
    document.addEventListener(TOAST_KEY, callback);
  }

  static removeToast(callback: (message: any) => void) {
    /** callback must be **exactly** what was provided to addEventListener */
    document.removeEventListener(TOAST_KEY, callback);
  }

  static onFun(callback: (message: any) => void) {
    /** callback must be **exactly** what will be provided to removeEventListener */
    document.addEventListener(FUN_KEY, callback);
  }

  static removeFun(callback: (message: any) => void) {
    /** callback must be **exactly** what was provided to addEventListener */
    document.removeEventListener(FUN_KEY, callback);
  }

  static announce(config: IAnnouncement) {
    dispatch({
      header: 'common.announcement',
      color: config.color,
      message_md: config.message,
      inbox: true,
      delay_ms: config.delay_ms ?? DEFAULT_DELAY_MS,
    });
  }

  static getColorFromLevel(level: ErrorLevel | undefined): RadixColor {
    switch (level) {
      case ErrorLevel.info: {
        return RADIX.COLOR.INFO;
      }

      case ErrorLevel.warning: {
        return RADIX.COLOR.WARNING;
      }

      case ErrorLevel.error: {
        return RADIX.COLOR.DANGER;
      }

      default: {
        return 'gray';
      }
    }
  }

  // translates IErrorType into INotification
  static userError(errorType: IErrorType) {
    // will fallback to using english if undefined
    const translatedMsg = (() => {
      switch (i18n.language) {
        case 'ja': {
          return errorType.user_message_japanese;
        }

        case 'ko': {
          return errorType.user_message_korean;
        }

        default: {
          return '';
        }
      }
    })();

    // we coalesce with || instead of ?? because empty strings are possible values for the messages
    dispatch({
      message_md: translatedMsg || errorType.user_message,
      header: '--hidden--',
      hideHeader: true,
      color: NotifyHelper.getColorFromLevel(errorType.level),
      level: errorType.level ?? ErrorLevel.error,

      // based on IErrorNotification fields
      delay_ms: errorType.delay_ms ?? DEFAULT_DELAY_MS,
      show_restart: errorType.show_restart,
      intercom_article_id: errorType.intercom_article_id,
    });
  }

  /** shorthand for simple errors */
  static error(config: INotification) {
    dispatch({
      ...config,
      header: t(config.header ?? 'common.error').toString(),
      color: RADIX.COLOR.DANGER,
      delay_ms: config.delay_ms ?? DEFAULT_DELAY_MS,
      level: config.level ?? ErrorLevel.error,
    });
  }

  /** shorthand for simple warnings */
  static warning(config: INotification) {
    dispatch({
      ...config,
      header: t(config.header ?? 'common.warning').toString(),
      color: RADIX.COLOR.WARNING,
      delay_ms: config.delay_ms ?? DEFAULT_DELAY_MS,
      level: config.level ?? ErrorLevel.warning,
    });
  }

  /** shorthand for simple warnings that only show on non-production environments */
  static devWarning(config: INotification) {
    if (env.production) {
      return;
    }

    dispatch({
      ...config,
      header: t(config.header ?? 'common.warning').toString(),
      color: RADIX.COLOR.WARNING,
      delay_ms: config.delay_ms ?? DEFAULT_DELAY_MS,
      level: config.level ?? ErrorLevel.warning,
    });
  }

  /** shorthand for simple success */
  static success(config: INotification) {
    dispatch({
      ...config,
      header: t(config.header ?? 'common.success').toString(),
      color: RADIX.COLOR.SUCCESS,
      delay_ms: config.delay_ms ?? DEFAULT_DELAY_MS,
      level: config.level ?? ErrorLevel.success,
    });
  }

  static primary(config: INotification) {
    dispatch({
      ...config,
      header: t(config.header ?? 'common.notification').toString(),
      delay_ms: config.delay_ms ?? DEFAULT_DELAY_MS,
    });
  }

  static secondary(config: INotification) {
    dispatch({
      ...config,
      header: t(config.header ?? 'common.notification').toString(),
      delay_ms: config.delay_ms ?? DEFAULT_DELAY_MS,
    });
  }

  static debug(config: INotification) {
    dispatch({
      ...config,
      header: t(config.header ?? 'common.debug').toString(),
      delay_ms: config.delay_ms ?? DEFAULT_DELAY_MS,
      level: config.level ?? ErrorLevel.debug,
      debug: true,
    });
  }

  static tempDebug(config: INotification) {
    dispatch({
      ...config,
      header: t(config.header ?? 'common.debug').toString(),
      delay_ms: config.delay_ms ?? DEFAULT_DELAY_MS,
      level: config.level ?? ErrorLevel.debug,
    });
  }

  static info(config: INotification) {
    dispatch({
      ...config,
      header: t(config.header ?? 'common.info').toString(),
      color: RADIX.COLOR.INFO,
      delay_ms: config.delay_ms ?? DEFAULT_DELAY_MS,
      level: config.level ?? ErrorLevel.info,
    });
  }

  /** chance: 0-1, default 1 (i.e. 100%) */
  static haveFun(chance = 1) {
    if (Math.random() >= chance) {
      return;
    }

    document.dispatchEvent(new CustomEvent(FUN_KEY, { detail: undefined }));
  }
}
