import { Box, Card, Flex, Grid, Heading } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { getPovTooltip, PlateView } from 'components/common/plate-view';
import { ReleaseView } from 'components/common/release-view';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPlateLoc, ITrajectory } from 'lib_ts/interfaces/pitches';
import { useContext, useMemo } from 'react';

const COMPONENT_NAME = 'BallFlightDesigner';

interface IProps {
  traj: ITrajectory;

  onUpdatePlate: (plate: IPlateLoc) => void;
  onUpdateRelease: (pos: { px: number; pz: number }) => void;
}

export const BallFlightDesigner = (props: IProps) => {
  const cookiesCx = useContext(CookiesContext);
  const authCx = useContext(AuthContext);
  const machineCx = useContext(MachineContext);

  if (!props.traj) {
    return <></>;
  }

  const plate = useMemo(() => TrajHelper.getPlateLoc(props.traj), [props.traj]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Grid columns={{ initial: '1', lg: '2' }} gap={RADIX.FLEX.GAP.MD}>
        <Card size="2">
          <Flex gap={RADIX.FLEX.GAP.MD} justify="between">
            <Heading mb="2" size={RADIX.HEADING.SIZE.SM}>
              {t('pd.plate-location')}
            </Heading>

            {getPovTooltip()}
          </Flex>

          <Flex justify="center">
            <Box>
              <PlateView
                cookiesCx={cookiesCx}
                authCx={authCx}
                machineCx={machineCx}
                plate_x={plate.plate_x}
                plate_z={plate.plate_z}
                onUpdate={props.onUpdatePlate}
                showControls
                landscape
              />
            </Box>
          </Flex>
        </Card>
        <Card size="2">
          <Heading mb="2" size={RADIX.HEADING.SIZE.SM}>
            {t('pd.release-position')}
          </Heading>

          <ReleaseView
            px={props.traj.px}
            pz={props.traj.pz}
            onUpdate={props.onUpdateRelease}
            showControls
          />
        </Card>
      </Grid>
    </ErrorBoundary>
  );
};
