import { ErrorBoundary } from 'components/common/error-boundary';
import { SelectCard } from 'components/sections/quick-session/steps/select-card';
import { SelectPitchHoC } from 'components/sections/quick-session/steps/select-pitch';
import { AimingProvider } from 'contexts/aiming.context';
import {
  QuickSessionContext,
  QuickSessionProvider,
  QuickSessionStep,
} from 'contexts/quick-session.context';
import { useContext } from 'react';

const COMPONENT_NAME = 'QuickSession';

export const QuickSessionHoC = () => {
  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <AimingProvider>
        <QuickSessionProvider>
          <QuickSession />
        </QuickSessionProvider>
      </AimingProvider>
    </ErrorBoundary>
  );
};

const QuickSession = () => {
  const { step } = useContext(QuickSessionContext);

  switch (step) {
    case QuickSessionStep.SelectPitch: {
      return <SelectPitchHoC />;
    }

    case QuickSessionStep.SelectCard:
    default: {
      return <SelectCard />;
    }
  }
};
