import { Box, Grid } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CookiesContext } from 'contexts/cookies.context';
import { CheckedContext } from 'contexts/layout/checked.context';
import { MachineContext } from 'contexts/machine.context';
import { MlbBrowseContext } from 'contexts/mlb-browse.context';
import { CookieKey } from 'enums/cookies.enums';
import { t } from 'i18next';
import { MlbSportId } from 'lib_ts/enums/mlb-stats-api/base.enum';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { useContext, useMemo } from 'react';

const COMPONENT_NAME = 'MlbStatsMainFilters';

export const MlbStatsMainFilters = () => {
  const machineCx = useContext(MachineContext);
  const browseCx = useContext(MlbBrowseContext);
  const checkedCx = useContext(CheckedContext);

  const seasonOptions = useMemo(() => browseCx.seasons, [browseCx.seasons]);

  const seasonKey = useMemo(() => Date.now(), [seasonOptions]);

  const teamOptions = useMemo(() => {
    // options per teams, unique per team name
    const teamsDict: { [teamName: string]: IOption } = {};

    browseCx.seasonGames.forEach((g) => {
      if (teamsDict[g.home.name]) {
        return;
      }

      teamsDict[g.home.name] = {
        label: g.home.name,
        value: g.home.teamPk.toString(),
      };
    });

    return Object.values(teamsDict).sort((a, b) =>
      a.label.localeCompare(b.label)
    );
  }, [browseCx.seasonGames]);

  const teamKey = useMemo(() => Date.now(), [teamOptions]);

  const pitcherOptions = useMemo(() => {
    return browseCx.seasonPlayers
      .filter((p) => p.isPitcher)
      .map((p) => {
        const o: IOption = {
          label: p.name,
          value: p._id,
        };

        return o;
      });
  }, [browseCx.seasonPlayers]);

  const pitcherKey = useMemo(() => Date.now(), [pitcherOptions]);

  const batterOptions = useMemo(() => {
    return browseCx.seasonPlayers.map((p) => {
      const o: IOption = {
        label: p.name,
        value: p._id,
      };

      return o;
    });
  }, [browseCx.seasonPlayers]);

  const batterKey = useMemo(() => Date.now(), [batterOptions]);

  const gameOptions = useMemo(() => {
    return browseCx.filteredGames.map((g) => {
      const o: IOption = {
        label: `${g.officialDate}: ${g.away.name} @ ${g.home.name}`,
        group: g.officialDate,
        value: g.gamePk.toString(),
      };

      return o;
    });
  }, [browseCx.filteredGames]);

  const buildOptions = useMemo(() => {
    // we need to add "(unsupported)" to the end of the labels for disabled options
    return machineCx.buildOptions.map((m) => {
      const o: IOption = {
        ...m,
        label: m.disabled
          ? `${t(m.label)} (${t('common.unsupported').toLowerCase()})`
          : m.label,
      };

      return o;
    });
  }, [machineCx.buildOptions]);

  const gameKey = useMemo(() => Date.now(), [gameOptions]);

  const { app, setCookie } = useContext(CookiesContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Grid columns="8" gap={RADIX.FLEX.GAP.SM}>
        <Box>
          <CommonSelectInput
            key={seasonKey}
            id="mlb-stats-season"
            name="season"
            placeholder="common.season"
            options={seasonOptions}
            value={browseCx.gameFilter.season?.toString()}
            onNumericChange={(v) => {
              if (v === browseCx.gameFilter.season) {
                return;
              }

              checkedCx.resetChecked();

              browseCx.mergeGameFilter({
                season: v,
              });
            }}
            disabled={browseCx.loading}
            optional
            skipSort
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="mlb-stats-sportId"
            name="sportId"
            placeholder="common.level"
            options={[
              { label: 'MLB', value: MlbSportId.MLB.toString() },
              { label: 'MiLB', value: MlbSportId.MiLB.toString() },
            ]}
            value={browseCx.gameFilter.sportId.toString()}
            onNumericChange={(v) => {
              if (v === browseCx.gameFilter.sportId) {
                return;
              }

              checkedCx.resetChecked();

              browseCx.mergeGameFilter({
                sportId: v,
              });
            }}
            disabled={browseCx.loading}
            skipSort
          />
        </Box>
        <Box>
          <CommonSearchInput
            key={pitcherKey}
            id="mlb-stats-pitchers"
            placeholder="common.pitcher"
            title={
              !browseCx.gameFilter.season
                ? 'Select a season first.'
                : 'Filter games and pitches by pitcher.'
            }
            disabled={browseCx.loading || !browseCx.gameFilter.season}
            values={browseCx.gameFilter.pitchers?.map((p) => p._id) ?? []}
            options={pitcherOptions}
            onChange={(ids) => {
              const players = browseCx.seasonPlayers.filter((p) =>
                ids.includes(p._id)
              );

              checkedCx.resetChecked();

              browseCx.mergeGameFilter({
                pitchers: players,
              });
            }}
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            key={batterKey}
            id="mlb-stats-batters"
            placeholder="common.batter"
            title={
              !browseCx.gameFilter.season
                ? 'Select a season first.'
                : 'Filter games and pitches by batter.'
            }
            disabled={browseCx.loading || !browseCx.gameFilter.season}
            values={browseCx.gameFilter.hitters?.map((p) => p._id) ?? []}
            options={batterOptions}
            onChange={(ids) => {
              const players = browseCx.seasonPlayers.filter((p) =>
                ids.includes(p._id)
              );

              checkedCx.resetChecked();

              browseCx.mergeGameFilter({
                hitters: players,
              });
            }}
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            key={teamKey}
            id="mlb-stats-team"
            name="teamPk"
            title={
              !browseCx.gameFilter.season
                ? 'Select a season first.'
                : 'Filter games by team (home or away).'
            }
            placeholder="common.team"
            options={teamOptions}
            values={
              browseCx.gameFilter.teamPk
                ? [browseCx.gameFilter.teamPk.toString()]
                : []
            }
            onChange={(v) => {
              const teamPk = v.length > 0 ? parseInt(v[0]) : undefined;

              if (teamPk === browseCx.gameFilter.teamPk) {
                return;
              }

              checkedCx.resetChecked();

              browseCx.mergeGameFilter({
                teamPk: teamPk,
              });
            }}
            disabled={browseCx.loading || !browseCx.gameFilter.season}
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            key={gameKey}
            id="mlb-stats-game"
            name="gamePk"
            title={
              !browseCx.gameFilter.season
                ? 'Select a season first.'
                : 'Filter pitches by game.'
            }
            placeholder="common.game"
            options={gameOptions}
            values={
              browseCx.gameFilter.gamePk
                ? [browseCx.gameFilter.gamePk.toString()]
                : []
            }
            onChange={(v) => {
              const gamePk = v.length > 0 ? parseInt(v[0]) : undefined;

              if (gamePk === browseCx.gameFilter.gamePk) {
                return;
              }

              checkedCx.resetChecked();

              browseCx.mergeGameFilter({
                gamePk: gamePk,
              });
            }}
            disabled={browseCx.loading || !browseCx.gameFilter.season}
            optional
            // newest games at the top
            reverseSort
          />
        </Box>
        <Box>
          {machineCx.activeModel && (
            <CommonSelectInput
              id="mlb-stats-build-priority"
              name="buildPriority"
              title={t('common.build-priority-tooltip').toString()}
              placeholder="common.priority"
              options={buildOptions}
              value={machineCx.getSupportedPriority(
                app.pitch_upload_options.priority
              )}
              onChange={(v) => {
                setCookie(CookieKey.app, {
                  pitch_upload_options: {
                    ...app.pitch_upload_options,
                    priority: v as BuildPriority,
                  },
                });
              }}
            />
          )}
        </Box>
        <Box>
          <CommonSelectInput
            id="mlb-stats-normalize-release"
            name="normalizeRelease"
            title={`${
              browseCx.normalizeRelease ? 'Normalize' : 'Do not normalize'
            } release position per pitcher when building multiple pitches.`}
            placeholder="pd.release-position"
            options={[
              {
                label: 'common.normalize-releases',
                value: 'true',
              },
              {
                label: 'common.original-releases',
                value: 'false',
              },
            ]}
            value={browseCx.normalizeRelease.toString()}
            onBooleanChange={(v) => browseCx.setNormalizeRelease(v)}
          />
        </Box>
      </Grid>
    </ErrorBoundary>
  );
};
