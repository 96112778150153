export const KBO_TEAMS = [
  'Doosan Bears',
  'Hanwha Eagles',
  'Kia Tigers',
  'Kiwoom Heroes',
  'KT Wiz',
  'LG Twins',
  'Lotte Giants',
  'NC Dinos',
  'Samsung Lions',
  'SSG Landers',
];
