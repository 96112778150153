import { PlateWarning } from '../../enums/plate.enums';

export interface IPlateLoc {
  /** in feet, + is towards first base from batter's POV */
  plate_x: number;
  /** in feet, + is higher from the ground */
  plate_z: number;
}

export interface IPlateLocExt extends IPlateLoc {
  _created: string;
  _id: string;
}

export const DEFAULT_PLATE: IPlateLoc = {
  plate_x: 0,
  plate_z: 2.5,
};

export interface ISafeLocation {
  location: IPlateLoc;
  warning: PlateWarning;
}
