import { Skeleton } from '@radix-ui/themes';
import { PitchDesignHoC } from 'components/sections/pitch-design';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { useContext } from 'react';

export const Design = () => {
  const { loading } = useContext(PitchDesignContext);

  // wait for design context before rendering anything
  if (loading) {
    return <Skeleton height="50%" />;
  }

  return <PitchDesignHoC />;
};
