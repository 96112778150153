import { Avatar, Badge, Box, Button, Flex, Heading } from '@radix-ui/themes';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { QuickSessionContext } from 'contexts/quick-session.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo } from 'react';

export const SelectPitchHeaderContent = () => {
  const { aggReady, isPitchTrained } = useContext(MatchingShotsContext);
  const { checkActive } = useContext(MachineContext);
  const { active, activePitches, avatarURLs, trainPitches } =
    useContext(QuickSessionContext);

  const isActive = checkActive(true);

  const untrained = useMemo(() => {
    return activePitches.filter((p) => !isPitchTrained(p));
  }, [activePitches, aggReady, isPitchTrained]);

  const details = active?.card;

  return (
    <Flex gap={RADIX.FLEX.GAP.MD} justify="between">
      <Avatar
        size="6"
        src={avatarURLs[details?.avatar_path ?? '']?.url}
        fallback={StringHelper.getInitials(active?.name)}
      />

      <Box flexGrow="1">
        <Heading size={RADIX.HEADING.SIZE.LG}>
          {details?.name ?? t('hitters.anonymous')}
        </Heading>

        <Flex gap={RADIX.FLEX.GAP.SM}>
          {details?.release && (
            <Badge color={RADIX.COLOR.NEUTRAL}>
              {PitchListHelper.shortPitcherRelease(details.release)}
            </Badge>
          )}

          {details?.level && (
            <Badge color={RADIX.COLOR.NEUTRAL}>{details.level}</Badge>
          )}

          {details?.hand && (
            <Badge color={RADIX.COLOR.NEUTRAL}>{details.hand}</Badge>
          )}
        </Flex>
      </Box>

      {isActive && untrained.length > 0 && (
        <Box>
          <Button
            size={RADIX.BUTTON.SIZE.MD}
            disabled={!isActive}
            color={RADIX.COLOR.WARNING}
            className="width-200px"
            onClick={() => {
              if (!isActive) {
                return;
              }

              trainPitches(untrained);
            }}
          >
            {t('pu.train-all')}
          </Button>
        </Box>
      )}
    </Flex>
  );
};
