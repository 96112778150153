import { Flex } from '@radix-ui/themes';
import { CommonTextInput } from 'components/common/form/text';
import env from 'config';
import { HittersContext } from 'contexts/hitters.context';
import { QuickSessionContext } from 'contexts/quick-session.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const SelectPitchHeaderSidebar = () => {
  const { getInput } = useContext(HittersContext);
  const { tags, setTags } = useContext(QuickSessionContext);

  return (
    <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
      {getInput('level')}
      {getInput('hitter')}

      {env.enable.fire_tags && (
        <CommonTextInput
          id="quick-session-tags"
          value={tags}
          className="text-uppercase"
          placeholder={t('common.eg-tags').toString()}
          onChange={(v) => setTags(v ?? '')}
        />
      )}
    </Flex>
  );
};
