import { NotifyHelper } from 'classes/helpers/notify.helper';
import { GAME_DATA_ID } from 'components/sections/mlb-stats-browse';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';
import { DEFAULT_PITCH, IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { PitchesService } from 'services/pitches.service';

export interface IPitchDesignContext {
  loading: boolean;

  lastUpdated?: number;

  reference: IPitch;

  // only used by game data subsection
  readonly setReference: (pitch: IPitch) => void;

  // for forms and editors
  ball?: IBallChar;
  readonly setBall: (ball: IBallChar) => void;
}

const DEFAULT: IPitchDesignContext = {
  loading: true,

  reference: DEFAULT_PITCH,
  setReference: () => console.debug('not init'),

  setBall: () => console.debug('not init'),
};

export const PitchDesignContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const PitchDesignProvider: FC<IProps> = (props) => {
  const sectionsCx = useContext(SectionsContext);
  const machineCx = useContext(MachineContext);

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const [_lastUpdated, _setLastUpdated] = useState(DEFAULT.lastUpdated);

  // note: reference py may differ from machine plate distance if the pitch was originally created for a different machine
  const [_reference, _setReference] = useState<IPitch>(DEFAULT_PITCH);

  // ball py should always match machine plate distance
  const [_ball, _setBall] = useState(DEFAULT.ball);

  const safeSetReference = useCallback(
    (pitch: IPitch) => {
      if (machineCx.activeModel) {
        if (
          !machineCx.activeModel.supports_spins &&
          pitch.priority === BuildPriority.Spins
        ) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes spins but your machine's active model does not support spins.`,
          });
        }

        if (
          !machineCx.activeModel.supports_breaks &&
          pitch.priority === BuildPriority.Breaks
        ) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes breaks but your machine's active model does not support breaks.`,
          });
        }
      }

      const safePitch: IPitch = { ...pitch };

      if (!safePitch.breaks) {
        safePitch.breaks = { xInches: 0, zInches: 0 };
      }

      if (!safePitch.seams) {
        safePitch.seams = { latitude_deg: 0, longitude_deg: 0 };
      }

      _setReference(safePitch);

      setTimeout(() => {
        _setLoading(false);
      }, 100);
    },
    [machineCx.activeModel, _setLoading, _setReference]
  );

  const state: IPitchDesignContext = {
    loading: _loading,

    lastUpdated: _lastUpdated,

    reference: _reference,
    setReference: safeSetReference,

    ball: _ball,
    setBall: (ball) => {
      _setBall(ball);
    },
  };

  // listen to section changes to automatically get reference pitch
  useEffect(() => {
    if (sectionsCx.active.section !== SectionName.Pitches) {
      return;
    }

    if (sectionsCx.active.subsection !== SubSectionName.Design) {
      return;
    }

    // build the generic pitch now to avoid the need for load chars on mount of PD
    if (
      !sectionsCx.active.fragments ||
      sectionsCx.active.fragments.length === 0
    ) {
      if (_reference._id === GAME_DATA_ID) {
        console.debug('build generic > skipped (found game data)');
        _setReference({ ..._reference, _id: '' });

        setTimeout(() => {
          _setLoading(false);
        }, 100);
        return;
      }

      // generic pitch design w/o reference
      const defaultPitch = machineCx.getDefaultPitch();

      safeSetReference(defaultPitch);
      return;
    }

    const firstFragment = sectionsCx.active.fragments[0];

    if (firstFragment === _reference._id) {
      // skip unnecessary reload
      return;
    }

    _setLoading(true);

    // attempt to load the pitch by ID
    PitchesService.getInstance()
      .getOne(firstFragment)
      .then((result) => {
        if (!result) {
          NotifyHelper.error({
            message_md: t('common.request-failed-msg'),
          });
          _setLoading(false);
          return;
        }

        safeSetReference(result);
      })
      .catch((e) => {
        console.error(e);
        _setLoading(false);
      });
  }, [
    machineCx.machine,
    machineCx.activeModel,
    sectionsCx.active.section,
    sectionsCx.active.subsection,
    sectionsCx.active.fragments,
  ]);

  // for form inputs to automatically remount
  useEffect(() => {
    if (!_reference) {
      return;
    }

    _setBall(BallHelper.getCharsFromPitch(_reference));
    _setLastUpdated(Date.now());
  }, [_reference]);

  // keep ball py in sync with plate distance
  useEffect(() => {
    if (!_ball) {
      return;
    }

    _setBall({
      ..._ball,
      py: machineCx.machine.plate_distance,
    });
  }, [machineCx.machine.plate_distance]);

  return (
    <PitchDesignContext.Provider value={state}>
      {props.children}
    </PitchDesignContext.Provider>
  );
};
