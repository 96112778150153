export enum PlateOutcome {
  NoData = 'ND',
  Ball = 'BA',
  Strike = 'SK',
}

/** L C R
 *T
 *M
 *B
 */
export enum PlateH {
  L = 'L',
  C = 'C',
  R = 'R',
  Unknown = '?',
}

/** L C R
 *T
 *M
 *B
 */
export enum PlateV {
  T = 'T',
  M = 'M',
  B = 'B',
  Unknown = '?',
}

export enum PlateWarning {
  None,
  DangerousHorizontal,
  EllipseLeft,
  EllipseRight,
  EllipseBoth,
}
